import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  DocumentAttributes,
  TickerAttributes,
} from "../types/cms";
import {
  PeriodKey,
  SortingMethod,
  getDocumentsQueryParamsField,
  getDocumentsQueryParamsPopulateFields,
  getPeriodStartDate,
  getSortingParams,
} from "../utils";
import { AxiosResponse } from "axios";
import { fetchDataFromCMS } from "../cms-api";
import { DEAFULT_REPORT_LIST_SIZE } from "../constants";

export interface InsightsDocumentAPIItem
  extends CMSAPIDataItem<DocumentAttributes> {
  associated_ticker?: CMSAPIDataItem<TickerAttributes>;
}

export const useDocuments = (accessToken?: string | null) => {
  const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(true);
  const [documents, setDocuments] = useState<InsightsDocumentAPIItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [selectedTickers, setSelectedTickers] = useState<TickerAttributes[]>(
    [],
  );
  const [selectedPeriodFilter, setSelectedPeriodFilter] =
    useState<PeriodKey>("all_time");
  const [selectedSort, setSelectedSort] = useState<SortingMethod>("market_cap");
  const [error, setError] = useState<AxiosResponse<any, any> | null>(null);

  const queryParams = useMemo(() => {
    // if sorting by ticker market cap, get from ticker endpoints with documents field
    // if sorting by report date, from documents endpoint
    const sortingParams = getSortingParams(selectedSort);
    const queryFields = getDocumentsQueryParamsField(selectedSort);
    const populateFields = getDocumentsQueryParamsPopulateFields(selectedSort);
    const params: Record<string, any> = {
      sort: sortingParams,
      fields: queryFields,
      populate: populateFields,
      filters: {},
      pagination: {
        pageSize: DEAFULT_REPORT_LIST_SIZE,
        page: currentPage,
      },
    };
    if (selectedTickers.length) {
      params.filters["ticker"] = {
        $in: selectedTickers.map((ticker) => ticker.ticker),
      };
    }
    // TODO: check if this is still needed (7/23/2024)
    if (selectedPeriodFilter !== "all_time") {
      const periodStart = getPeriodStartDate(selectedPeriodFilter);
      const periodEnd = new Date(); // Use the current date as the end of the period
      params.filters["$or"] = [
        // Reports that start within the selected period
        {
          report_start_date: {
            $gte: periodStart.toISOString(),
            $lte: periodEnd.toISOString(),
          },
        },
        // Reports that end within the selected period
        {
          report_date: {
            $gte: periodStart.toISOString(),
            $lte: periodEnd.toISOString(),
          },
        },
        // Reports that started before and end after the selected period (spanning across it)
        {
          $and: [
            { report_start_date: { $lte: periodStart.toISOString() } },
            { report_date: { $gte: periodEnd.toISOString() } },
          ],
        },
      ];
    }
    return params;
  }, [currentPage, selectedTickers, selectedSort, selectedPeriodFilter]);

  const fetchDocuments = useCallback(async () => {
    // if sorting by ticker market cap, get from ticker endpoints with documents field
    // if sorting by report date, from documents endpoint
    const apiEndpoint =
      selectedSort === "market_cap" ? "/api/tickers" : "/api/documents";
    try {
      const { data, error } = await fetchDataFromCMS<
        | CMSAPIDataCollection<CMSAPIDataItem<TickerAttributes>>
        | CMSAPIDataCollection<InsightsDocumentAPIItem>
      >(apiEndpoint, accessToken, queryParams, {
        method: "get",
      });

      if (error || !data?.data) {
        throw error;
      }

      let documentsData: InsightsDocumentAPIItem[] = [];

      if (selectedSort === "market_cap") {
        documentsData = (
          data as CMSAPIDataCollection<CMSAPIDataItem<TickerAttributes>>
        ).data.reduce((tickerDocuments, tickerData) => {
          const tickerDocumentsData =
            tickerData.attributes.documents?.data || [];
          if (tickerDocumentsData.length) {
            const tickerDocument = {
              ...tickerDocumentsData[0],
              associated_ticker: tickerData,
            };
            tickerDocuments.push(tickerDocument);
          }
          return tickerDocuments;
        }, [] as InsightsDocumentAPIItem[]);
      } else {
        documentsData = (data as CMSAPIDataCollection<InsightsDocumentAPIItem>)
          .data;
      }

      if (currentPage === 1) {
        setDocuments(documentsData);
      } else {
        setDocuments((prevDocuments) => [...prevDocuments, ...documentsData]);
      }
      setHasMore(data.meta.pagination.pageCount > currentPage);
    } catch (fetchError: any) {
      setError(fetchError);
    } finally {
      setIsLoadingInitial(false);
      setIsLoadingMore(false);
    }
  }, [
    accessToken,
    currentPage,
    selectedTickers,
    selectedSort,
    selectedPeriodFilter,
  ]);

  // Effect to handle initial load and reload when filters change
  useEffect(() => {
    if (isLoadingInitial && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingInitial, fetchDocuments, accessToken]);

  // Effect to handle loading more documents
  useEffect(() => {
    if (isLoadingMore && hasMore && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingMore, hasMore, fetchDocuments, accessToken]);

  const loadMoreDocuments = useCallback(() => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  }, [hasMore, isLoadingMore]);

  const resetAndFetch = useCallback(() => {
    setIsLoadingInitial(true);
    setDocuments([]);
    setCurrentPage(1);
    setHasMore(true);
  }, []);

  const setSelectedTickersWithReset = useCallback(
    (tickers: TickerAttributes[]) => {
      setSelectedTickers(tickers);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  const setSelectedPeriodFilterWithReset = useCallback(
    (period: PeriodKey) => {
      setSelectedPeriodFilter(period);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  const setSelectedSortWithReset = useCallback(
    (sort: SortingMethod) => {
      setSelectedSort(sort);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  return {
    documents,
    isLoadingInitial,
    error,
    selectedTickers,
    setSelectedTickersWithReset,
    selectedPeriodFilter,
    setSelectedPeriodFilterWithReset,
    selectedSort,
    setSelectedSortWithReset,
    hasMore,
    isLoadingMore,
    loadMoreDocuments,
  };
};
