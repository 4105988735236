import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/authContext";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  ThemesBasketAttributes,
  ThemesDocumentAttributes,
} from "../../types/cms";
import useNorthstar from "../../hooks/northstarHook";
import ReportList from "../ReportList";
import LoadingStatus from "../LoadingStatus";
import PageBoundary from "../PageBoundary";
import ThemesReportFilter from "../ThemesReportFilter";
import { useThemesDocuments } from "../../hooks/themesDocumentsHook";

const ThemesInsightsPage = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const {
    isLoadingInitial,
    hasMore,
    loadMoreDocuments,
    isLoadingMore,
    selectedBaskets,
    setSelectedBasketsWithReset,
    themesDocuments,
  } = useThemesDocuments(accessToken);

  const {
    data: themesBasketsData,
    isLoading: isLoadingThemesBaskets,
    error: errorLoadingbasketss,
  } = useNorthstar<
    CMSAPIDataCollection<CMSAPIDataItem<ThemesBasketAttributes>>
  >(
    `/api/themes-baskets`,
    accessToken,
    {
      method: "get",
    },
    {
      fields: ["order", "basket", "basket_name", "basket_desp"],
      sort: [{ order: "asc" }, { basket_name: "asc" }],
    },
  );
  const themesBaskets =
    themesBasketsData?.data?.map((basket) => basket.attributes) || [];

  const {
    data: latestThemesDocuments,
    isLoading: isLoadingLatestThemesDocuments,
    error: errorLoadingLatestThemesDocuments,
  } = useNorthstar<CMSAPIDataItem<ThemesDocumentAttributes>[]>(
    `/api/latest-themes-documents`,
    accessToken,
    {
      method: "get",
    },
  );

  useEffect(() => {
    let ignore = true;
    if (
      (errorLoadingLatestThemesDocuments?.status === 401 ||
        errorLoadingbasketss?.status === 401) &&
      !ignore &&
      clearIdentity
    ) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [errorLoadingLatestThemesDocuments?.status, errorLoadingbasketss?.status]);

  let reportListTemplate;

  if (
    isLoadingThemesBaskets ||
    isLoadingLatestThemesDocuments ||
    isLoadingInitial
  ) {
    reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  } else if (errorLoadingbasketss || errorLoadingLatestThemesDocuments) {
    reportListTemplate = <LoadingStatus loadingType="reports_error" />;
  } else if (!latestThemesDocuments?.length) {
    reportListTemplate = <LoadingStatus loadingType="reports_empty" />;
  } else if (selectedBaskets.length === 0 && latestThemesDocuments) {
    reportListTemplate = (
      <ReportList reports={latestThemesDocuments} reportType="Themes" />
    );
  } else {
    reportListTemplate = (
      <ReportList
        reports={themesDocuments}
        reportType="Themes"
        hasMore={hasMore}
        loadMore={loadMoreDocuments}
        isLoadingMore={isLoadingMore}
      />
    );
  }

  return (
    <PageBoundary className="w-full">
      <ThemesReportFilter
        baskets={themesBaskets}
        selectedBaskets={selectedBaskets}
        setSelectedBaskets={setSelectedBasketsWithReset}
        loadingBaskets={isLoadingThemesBaskets}
      />
      {reportListTemplate}
    </PageBoundary>
  );
};

export default ThemesInsightsPage;
