import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/authContext";
import useFetchAllTickers from "../../hooks/tickersHook";
import { useDocuments } from "../../hooks/documentsHook";
import LoadingStatus from "../../components/LoadingStatus";
import PageBoundary from "../../components/PageBoundary";
import ReportFilter from "../../components/ReportFilter";
import ReportList from "../../components/ReportList";

const EquitiesInsightsPage = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const {
    tickers: tickersData,
    isLoading: isLoadingTickers,
    error: errorLoadingTickers,
  } = useFetchAllTickers(accessToken);
  const tickers = tickersData?.map((ticker) => ticker.attributes) || [];

  const {
    isLoadingInitial,
    error: errorLoadingDocuments,
    documents,
    selectedTickers,
    setSelectedTickersWithReset,
    selectedPeriodFilter,
    setSelectedPeriodFilterWithReset,
    selectedSort,
    setSelectedSortWithReset,
    hasMore,
    loadMoreDocuments,
    isLoadingMore,
  } = useDocuments(accessToken);

  useEffect(() => {
    let ignore = true;
    if (errorLoadingDocuments?.status === 401 && !ignore && clearIdentity) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [errorLoadingDocuments?.status]);

  let reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  if (isLoadingInitial) {
    reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  } else if (errorLoadingDocuments || errorLoadingTickers) {
    reportListTemplate = <LoadingStatus loadingType="reports_error" />;
  } else if (documents.length === 0) {
    reportListTemplate = <LoadingStatus loadingType="reports_empty" />;
  } else {
    reportListTemplate = (
      <ReportList
        reports={documents}
        hasMore={hasMore}
        loadMore={loadMoreDocuments}
        isLoadingMore={isLoadingMore}
        reportType="Equities"
      />
    );
  }

  return (
    <PageBoundary className="w-full">
      <ReportFilter
        tickers={tickers}
        selectedTickers={selectedTickers}
        setSelectedTickers={setSelectedTickersWithReset}
        selectedPeriodFilter={selectedPeriodFilter}
        setSelectedPeriodFilter={setSelectedPeriodFilterWithReset}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSortWithReset}
        loadingTickers={isLoadingTickers}
      />
      {reportListTemplate}
    </PageBoundary>
  );
};

export default EquitiesInsightsPage;
