import React from "react";
import { ThemesBasketAttributes } from "../../types/cms";
import { sortArrayByProperty } from "../../utils";
import AutocompleteDropdown from "../AutocompleteDropdown";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Chip } from "@mui/material";

interface ThemesReportFilterProps {
  baskets: ThemesBasketAttributes[];
  selectedBaskets: ThemesBasketAttributes[];
  setSelectedBaskets: (baskets: ThemesBasketAttributes[]) => void;
  loadingBaskets: boolean;
}

const ThemesReportFilter: React.FC<ThemesReportFilterProps> = ({
  baskets,
  selectedBaskets,
  setSelectedBaskets,
  loadingBaskets,
}) => {
  const sortedBaskets = sortArrayByProperty(baskets, "basket_name");

  const handleBasketChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: ThemesBasketAttributes[],
    _reason: string,
  ) => {
    setSelectedBaskets(newValue);
  };

  const customFilterOptions = (
    options: ThemesBasketAttributes[],
    { inputValue }: { inputValue: string },
  ): ThemesBasketAttributes[] => {
    const searchVal = inputValue.toLowerCase();
    return options.filter((option) => {
      const basketNameMatch = option.basket_name
        .toLowerCase()
        .includes(searchVal);
      const basketDespMatch = option.basket_desp
        .toLowerCase()
        .includes(searchVal);
      return basketNameMatch || basketDespMatch;
    });
  };

  return (
    <div className="w-full flex justify-center mt-4 xs:mt-5 sm:mt-6">
      <div className="sr-only">Filters</div>
      <div className="w-full lg:w-[64rem]">
        <AutocompleteDropdown
          label="Theme"
          placeholder="Search for a theme"
          value={selectedBaskets}
          onChange={handleBasketChange}
          isOptionEqualToValue={(option, value) =>
            option.basket === value.basket
          }
          options={sortedBaskets.map((basket) => ({
            label: basket.basket_name,
            value: basket,
          }))}
          filterOptions={customFilterOptions}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "rgb(17,24,39)",
                  fontWeight: "600",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                {option.basket_name}
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  paddingY: "1rem",
                  color: "rgb(55,65,81)",
                  textAlign: "left",
                }}
              >
                {option.basket_desp}
              </Typography>
            </Box>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={`${option.basket_name}`}
                // Pass the index directly to getTagProps as per MUI's signature
                {...getTagProps({ index })}
              />
            ))
          }
          loading={loadingBaskets}
        />
      </div>
    </div>
  );
};

export default ThemesReportFilter;
