import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  ThemesBasketAttributes,
  ThemesDocumentAttributes,
} from "../types/cms";
import { AxiosResponse } from "axios";
import { getThemesDocumentsListQueryParamsFields } from "../utils";
import { fetchDataFromCMS } from "../cms-api";
import { DEAFULT_REPORT_LIST_SIZE } from "../constants";

export const useThemesDocuments = (accessToken?: string | null) => {
  const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(true);
  const [themesDocuments, setThemesDocuments] = useState<
    CMSAPIDataItem<ThemesDocumentAttributes>[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<AxiosResponse<any, any> | null>(null);

  const [selectedBaskets, setSelectedBaskets] = useState<
    ThemesBasketAttributes[]
  >([]);

  const queryParams = useMemo(() => {
    const queryFields = getThemesDocumentsListQueryParamsFields();
    const params: Record<string, any> = {
      fields: queryFields,
      filters: {},
      sort: ["report_date:desc"],
      pagination: {
        pageSize: DEAFULT_REPORT_LIST_SIZE,
        page: currentPage,
      },
    };
    if (selectedBaskets.length) {
      params.filters["basket"] = {
        $in: selectedBaskets.map((basket) => basket.basket),
      };
    }
    return params;
  }, [currentPage, selectedBaskets]);

  const fetchDocuments = useCallback(async () => {
    const apiEndpoint = `/api/themes-documents`;
    try {
      const { data, error } = await fetchDataFromCMS<
        CMSAPIDataCollection<CMSAPIDataItem<ThemesDocumentAttributes>>
      >(apiEndpoint, accessToken, queryParams, {
        method: "get",
      });
      if (error || !data?.data) {
        throw error;
      }

      if (currentPage === 1) {
        setThemesDocuments(data.data);
      } else {
        setThemesDocuments((prev) => [...prev, ...data.data]);
      }
      setHasMore(data.meta.pagination.pageCount > currentPage);
    } catch (fetchError: any) {
      setError(fetchError);
    } finally {
      setIsLoadingInitial(false);
      setIsLoadingMore(false);
    }
  }, [accessToken, currentPage, selectedBaskets]);

  // Effect to handle initial load and reload when filters change
  useEffect(() => {
    if (isLoadingInitial && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingInitial, fetchDocuments, accessToken]);

  // Effect to handle loading more documents
  useEffect(() => {
    if (isLoadingMore && hasMore && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingMore, hasMore, fetchDocuments, accessToken]);

  const loadMoreDocuments = useCallback(() => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  }, [hasMore, isLoadingMore]);

  const resetAndFetch = useCallback(() => {
    setIsLoadingInitial(true);
    setThemesDocuments([]);
    setCurrentPage(1);
    setHasMore(true);
  }, []);

  const setSelectedBasketsWithReset = useCallback(
    (baskets: ThemesBasketAttributes[]) => {
      setSelectedBaskets(baskets);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  return {
    themesDocuments,
    isLoadingInitial,
    error,
    hasMore,
    isLoadingMore,
    loadMoreDocuments,
    selectedBaskets,
    setSelectedBasketsWithReset,
  };
};
