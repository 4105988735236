import React from "react";
import { MacroRegionAttributes, MacroTopicAttributes } from "../../types/cms";
import AutocompleteDropdown from "../AutocompleteDropdown";
import { Chip } from "@mui/material";
import { sortArrayByProperty } from "../../utils";

interface MacroReportFilterProps {
  macroRegions: MacroRegionAttributes[];
  selectedMacroRegions: MacroRegionAttributes[];
  setSelectedMacroRegions: (regions: MacroRegionAttributes[]) => void;
  loadingMacroRegions: boolean;
  macroTopics: MacroTopicAttributes[];
  selectedMacroTopics: MacroTopicAttributes[];
  setSelectedMacroTopics: (topics: MacroTopicAttributes[]) => void;
  loadingMacroTopics: boolean;
}

const MacroReportFilter: React.FC<MacroReportFilterProps> = ({
  macroRegions,
  selectedMacroRegions,
  setSelectedMacroRegions,
  loadingMacroRegions,
  macroTopics,
  selectedMacroTopics,
  setSelectedMacroTopics,
  loadingMacroTopics,
}) => {
  const sortedRegions = sortArrayByProperty(macroRegions, "region");
  const sortedTopics = sortArrayByProperty(macroTopics, "topic");

  const handleRegionChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: MacroRegionAttributes[],
    _reason: string,
  ) => {
    setSelectedMacroRegions(newValue);
  };

  const handleTopicChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: MacroTopicAttributes[],
    _reason: string,
  ) => {
    setSelectedMacroTopics(newValue);
  };

  return (
    <div className="w-full flex justify-center mt-4 xs:mt-5 sm:mt-6">
      <div className="sr-only">Filters</div>
      <div className="w-full xl:w-[75rem] grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-5 xl:gap-4 2xl:gap-5 3xl:gap-6">
        <AutocompleteDropdown
          label="Region"
          placeholder="Select a region"
          value={selectedMacroRegions}
          onChange={handleRegionChange}
          isOptionEqualToValue={(option, value) =>
            option.region === value.region
          }
          options={sortedRegions.map((region) => ({
            label: region.region,
            value: region,
          }))}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={`${option.region}`}
                // Pass the index directly to getTagProps as per MUI's signature
                {...getTagProps({ index })}
              />
            ))
          }
          loading={loadingMacroRegions}
        />
        <AutocompleteDropdown
          label="Topic"
          placeholder="Select a topic"
          value={selectedMacroTopics}
          onChange={handleTopicChange}
          isOptionEqualToValue={(option, value) => option.topic === value.topic}
          options={sortedTopics.map((topic) => ({
            label: topic.topic,
            value: topic,
          }))}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={`${option.topic}`}
                // Pass the index directly to getTagProps as per MUI's signature
                {...getTagProps({ index })}
              />
            ))
          }
          loading={loadingMacroTopics}
        />
      </div>
    </div>
  );
};

export default MacroReportFilter;
